import React from "react";
import { PAGE_PADDING, CONTENT_MAX_WIDTH_INCLUDING_PADDING } from "./PageWidthGrid";

const PassportLogo = () => (
  <svg width="714" height="318" viewBox="0 0 714 318" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M344.922 313.784L13.078 123.532C5.60475 119.244 1 111.328 1 102.752V-277.752C1 -286.328 5.60475 -294.244 13.078 -298.532L344.922 -488.784C352.395 -493.072 361.605 -493.072 369.078 -488.784L700.922 -298.532C708.395 -294.244 713 -286.328 713 -277.752V102.752C713 111.328 708.395 119.244 700.922 123.532L369.078 313.784C361.605 318.072 352.395 318.072 344.922 313.784ZM73.4682 88.9143L344.922 244.55C352.395 248.837 361.605 248.837 369.078 244.55L640.532 88.9143C648.005 84.6265 652.61 76.7106 652.61 68.135V-243.12C652.61 -251.696 648.005 -259.612 640.532 -263.9L369.078 -419.535C361.605 -423.822 352.395 -423.822 344.922 -419.535L73.4682 -263.9C65.9949 -259.612 61.3902 -251.696 61.3902 -243.12V68.135C61.3902 76.7106 65.9949 84.6265 73.4682 88.9143Z"
      fill="url(#paint0_linear_7391_2920)"
    />
    <path
      d="M344.914 -364.482L121.471 -236.373C113.997 -232.085 109.393 -224.169 109.393 -215.593V26.7725C109.393 43.9088 118.602 59.7556 133.549 68.3162L160.724 83.8932C164.755 86.202 169.783 83.3235 169.783 78.7059V-180.961C169.783 -189.536 174.387 -197.453 181.861 -201.74L344.914 -295.217C352.387 -299.505 361.597 -299.505 369.07 -295.217L532.124 -201.74C539.597 -197.453 544.202 -189.536 544.202 -180.961V6.00824C544.202 14.5839 539.597 22.4998 532.124 26.7875L369.07 120.265C361.597 124.552 352.387 124.552 344.914 120.265L290.563 89.1105C283.09 84.8227 278.485 76.9067 278.485 68.3312V-118.638C278.485 -127.213 283.09 -135.129 290.563 -139.417L344.914 -170.571C352.387 -174.859 361.597 -174.859 369.07 -170.571L423.421 -139.417C430.895 -135.129 435.499 -127.213 435.499 -118.638V-56.3149C435.499 -47.7393 430.895 -39.8232 423.421 -35.5354L396.246 -19.9585C392.215 -17.6497 387.187 -20.5282 387.187 -25.1458V-90.5123C387.187 -99.0879 382.583 -107.004 375.109 -111.292L363.031 -118.218C359.287 -120.362 354.697 -120.362 350.953 -118.218L335.856 -109.567C330.254 -106.359 326.797 -100.407 326.797 -93.9906V40.8804C326.797 49.4859 331.447 57.4319 338.966 61.7047L344.793 65.0029C352.252 69.2308 361.416 69.2157 368.859 64.9429L483.812 -0.963226C491.285 -5.25101 495.89 -13.1669 495.89 -21.7426V-153.315C495.89 -161.891 491.285 -169.807 483.812 -174.094L369.07 -239.881C361.597 -244.168 352.387 -244.168 344.914 -239.881L230.173 -174.094C222.7 -169.807 218.095 -161.891 218.095 -153.315V102.888C218.095 111.464 222.7 119.38 230.173 123.668L344.914 189.454C352.387 193.742 361.597 193.742 369.07 189.454L592.514 61.3449C599.987 57.0571 604.592 49.141 604.592 40.5654V-215.638C604.592 -224.214 599.987 -232.13 592.514 -236.418L369.07 -364.527C361.597 -368.815 352.387 -368.815 344.914 -364.527V-364.482Z"
      fill="url(#paint1_linear_7391_2920)"
    />
    <path
      d="M344.922 313.784L13.078 123.532C5.60475 119.244 1 111.328 1 102.752V-277.752C1 -286.328 5.60475 -294.244 13.078 -298.532L344.922 -488.784C352.395 -493.072 361.605 -493.072 369.078 -488.784L700.922 -298.532C708.395 -294.244 713 -286.328 713 -277.752V102.752C713 111.328 708.395 119.244 700.922 123.532L369.078 313.784C361.605 318.072 352.395 318.072 344.922 313.784ZM73.4682 88.9143L344.922 244.55C352.395 248.837 361.605 248.837 369.078 244.55L640.532 88.9143C648.005 84.6265 652.61 76.7106 652.61 68.135V-243.12C652.61 -251.696 648.005 -259.612 640.532 -263.9L369.078 -419.535C361.605 -423.822 352.395 -423.822 344.922 -419.535L73.4682 -263.9C65.9949 -259.612 61.3902 -251.696 61.3902 -243.12V68.135C61.3902 76.7106 65.9949 84.6265 73.4682 88.9143Z"
      stroke="url(#paint2_radial_7391_2920)"
      strokeOpacity="0.5"
    />
    <path
      d="M344.914 -364.482L121.471 -236.373C113.997 -232.085 109.393 -224.169 109.393 -215.593V26.7725C109.393 43.9088 118.602 59.7556 133.549 68.3162L160.724 83.8932C164.755 86.202 169.783 83.3235 169.783 78.7059V-180.961C169.783 -189.536 174.387 -197.453 181.861 -201.74L344.914 -295.217C352.387 -299.505 361.597 -299.505 369.07 -295.217L532.124 -201.74C539.597 -197.453 544.202 -189.536 544.202 -180.961V6.00824C544.202 14.5839 539.597 22.4998 532.124 26.7875L369.07 120.265C361.597 124.552 352.387 124.552 344.914 120.265L290.563 89.1105C283.09 84.8227 278.485 76.9067 278.485 68.3312V-118.638C278.485 -127.213 283.09 -135.129 290.563 -139.417L344.914 -170.571C352.387 -174.859 361.597 -174.859 369.07 -170.571L423.421 -139.417C430.895 -135.129 435.499 -127.213 435.499 -118.638V-56.3149C435.499 -47.7393 430.895 -39.8232 423.421 -35.5354L396.246 -19.9585C392.215 -17.6497 387.187 -20.5282 387.187 -25.1458V-90.5123C387.187 -99.0879 382.583 -107.004 375.109 -111.292L363.031 -118.218C359.287 -120.362 354.697 -120.362 350.953 -118.218L335.856 -109.567C330.254 -106.359 326.797 -100.407 326.797 -93.9906V40.8804C326.797 49.4859 331.447 57.4319 338.966 61.7047L344.793 65.0029C352.252 69.2308 361.416 69.2157 368.859 64.9429L483.812 -0.963226C491.285 -5.25101 495.89 -13.1669 495.89 -21.7426V-153.315C495.89 -161.891 491.285 -169.807 483.812 -174.094L369.07 -239.881C361.597 -244.168 352.387 -244.168 344.914 -239.881L230.173 -174.094C222.7 -169.807 218.095 -161.891 218.095 -153.315V102.888C218.095 111.464 222.7 119.38 230.173 123.668L344.914 189.454C352.387 193.742 361.597 193.742 369.07 189.454L592.514 61.3449C599.987 57.0571 604.592 49.141 604.592 40.5654V-215.638C604.592 -224.214 599.987 -232.13 592.514 -236.418L369.07 -364.527C361.597 -368.815 352.387 -368.815 344.914 -364.527V-364.482Z"
      stroke="url(#paint3_radial_7391_2920)"
      strokeOpacity="0.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7391_2920"
        x1="900.128"
        y1="-527.117"
        x2="93.4709"
        y2="186.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22645C" />
        <stop offset="1" stopColor="#122B33" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7391_2920"
        x1="900.128"
        y1="-527.117"
        x2="93.4709"
        y2="186.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22645C" />
        <stop offset="1" stopColor="#122B33" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_7391_2920"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(357 -87.5) rotate(90) scale(404.5 356)"
      >
        <stop offset="0.33754" stopColor="#6CB6AD" />
        <stop offset="1" stopColor="#041412" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_7391_2920"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(357 -87.5) rotate(90) scale(404.5 356)"
      >
        <stop offset="0.33754" stopColor="#6CB6AD" />
        <stop offset="1" stopColor="#041412" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

const BodyWrapper = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div
    className={`${PAGE_PADDING} ${CONTENT_MAX_WIDTH_INCLUDING_PADDING} grid w-full justify-self-center overflow-x-hidden pb-4 mb-8 ${className}`}
  >
    <div className="flex col-start-1 row-start-1 justify-center overflow-hidden">
      <PassportLogo />
    </div>
    <div className="mt-20 col-start-1 row-start-1">{children}</div>
  </div>
);

export default BodyWrapper;
